<template>
  <v-autocomplete
    rounded
    filled
    :items="items"
    label="Unidade"
    item-text="nome"
    item-value="id"
    v-model="selectedItems"
    chips
    multiple
    return-object
    deletable-chips
  >
    <template v-slot:selection="{ item }">
      <v-chip close small @click:close="removeItem(item)">
        <span>{{ item.nome }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "example-filter",

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      items: [],
      selectedItems: this.value,
    };
  },

  watch: {
    value(value) {
      this.selectedItems = value;
    },
    selectedItems(value) {
      this.$emit("input", value);
    },
  },

  methods: {
    removeItem(item) {
      let index = this.selectedItems.indexOf(item);
      if (index != -1) {
        this.selectedItems.splice(index, 1);
      } else if (item.id) {
        let itemToRemove = this.selectedItems.filter((i) => i.id === item.id);
        if (itemToRemove.length > 0) {
          index = this.selectedItems.indexOf(itemToRemove[0]);
          this.selectedItems.splice(index, 1);
        }
      }
    },
    buscarUnidades() {
      const self = this;
      self.$http
        .get(`/unidade?max=-1`)
        .then((response) => {
          this.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar unidades",
          });
        });
    },
  },

  created() {
    this.buscarUnidades();
  },
};
</script>